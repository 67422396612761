import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import NewJob from '../components/NewJob';
import Message from '../components/Message';
import Seo from '../components/seo';

const NewJobPage = () => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const data = useSelector(({ userData }) => userData);

  /**
   * Kick users in HR group.
   */
  useEffect(() => {
    if (data.groups.length > 0) {
      if (data.groups.indexOf('hr') > -1) {
        setErrorMessage(true);
        setShowForm(false);
        return;
      }

      setShowForm(true);
    }
  }, [data]);

  return (
    <>
      <Seo title="Post a Job" />
      {!showForm && errorMessage && (
        <Message message="An error occurred. Please check if you have permission to create a new job posting." />
      )}
      {showForm && !errorMessage && <NewJob />}
    </>
  );
};

export default NewJobPage;
