import React from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { navigate } from 'gatsby';

import { createJobGQL } from '../apollo/mutations';
import { createJob } from '../actions';
import Utils from '../utils';

import JobForm from './JobForm';

const NewJob = () => {
  const defaultFormValues = {
    title: '',
    company: { name: '', url: '' },
    type: '',
    category: '',
    workExperience: '',
    benefits: [],
    location: { country: 'Singapore', city: '' },
    salary: [],
    skillRequirements: [],
    description: '',
  };
  const [createOneJob] = Utils.useActions([createJob]);
  const [createJobPosting] = useMutation(createJobGQL, {
    onCompleted: (results) => {
      if (results === null) {
        toast.error('An error occurred. Failed to create job posting.');
      } else {
        createOneJob(results.createJob);
        toast.success('Job successfully created and posted.');
        setTimeout(() => {
          navigate('/');
        }, 500);
      }
    },
    onError: () => {
      toast.error('An error occurred. Failed to create job posting.');
    },
  });

  return (
    <JobForm
      defaultFormValues={defaultFormValues}
      graphqlQuery={createJobPosting}
      submitButton={{ title: 'Publish a new job posting', text: 'Publish Job' }}
    />
  );
};

export default NewJob;
